:root {
  --fd-font-family: 'Roboto', sans-serif;

  --fd-primary-color: #028a0f;
  --fd-primary-color-dark: #028a0f;
  --fd-primary-color-darkest: #028a0f;
  --fd-primary-color-light: #028a0f;
  --fd-primary-color-lightest: #028a0f;
  --fd-primary-color-hover: var(--fd-primary-color-darkest);
  --fd-primary-color-focus: var(--fd-primary-color-darkest);
  --fd-primary-box-shadow: 0;
  --fd-primary-box-shadow-hover: 0;
  --fd-primary-box-shadow-focus: 0 0 0 5px #43eb6579;
  --fd-primary-card-box-shadow: 0 4px 8px 0 #43eb9d23, 0 6px 20px 0 #43eba83d;
  --fd-primary-text-field-box-shadow-focus: 0 0 10px var(--fd-primary-color);

  --fd-secondary-color: #ebae43;
  --fd-secondary-color-dark: #946b24;
  --fd-secondary-color-light: #f1dbb4;
  --fd-secondary-color-hover: var(--fd-secondary-color-dark);
  --fd-secondary-color-focus: var(--fd-secondary-color-dark);
  --fd-secondary-box-shadow: 0;
  --fd-secondary-box-shadow-hover: 0;
  --fd-secondary-box-shadow-focus: 0 0 0 5px #ebae4379;
  --fd-secondary-card-box-shadow: 0 4px 8px 0 #ebae4323, 0 6px 20px 0 #ebae433d;
  --fd-secondary-text-field-box-shadow-focus: 0 0 10px var(--fd-secondary-color);

  --fd-tertiary-color: #5a3740;
  --fd-tertiary-color-dark: #5a3743;
  --fd-tertiary-color-light: #5a373c;
  --fd-tertiary-color-hover: #5a373c;
  --fd-tertiary-color-focus: #5a373c;
  --fd-tertiary-box-shadow: 0;
  --fd-tertiary-box-shadow-hover: 0;
  --fd-tertiary-box-shadow-focus: 0 0 0 5px #5a373c79;
  --fd-tertiary-card-box-shadow: 0 4px 8px 0 #5a373c23, 0 6px 20px 0 #5a373c3d;
  --fd-tertiary-text-field-box-shadow-focus: 0 0 10px var(--fd-tertiary-color);

  --fd-success-color: #21ba1e;
  --fd-success-color-dart: #146217;
  --fd-success-color-light: #9cd1a5;
  --fd-success-color-hover: #21ba1e;
  --fd-success-color-focus: #21ba1e;
  --fd-success-box-shadow: 0;
  --fd-success-box-shadow-hover: 0;
  --fd-success-box-shadow-focus: 0 0 0 5px #21ba1e79;
  --fd-success-card-box-shadow: 0 4px 8px 0 #21ba1e23, 0 6px 20px 0 #21ba1e3d;
  --fd-success-text-field-box-shadow-focus: 0 0 10px var(--fd-success-color);

  --fd-warning-color: #ebae43;
  --fd-warning-color-hover: #ebae43;
  --fd-warning-color-focus: #ebae43;
  --fd-warning-box-shadow: 0;
  --fd-warning-box-shadow-hover: 0;
  --fd-warning-box-shadow-focus: 0 0 0 5px #ebae4379;
  --fd-warning-card-box-shadow: 0 4px 8px 0 #ebae4323, 0 6px 20px 0 #ebae433d;
  --fd-warning-text-field-box-shadow-focus: 0 0 10px var(--fd-primary-warning);

  --fd-danger-color: #eb361a;
  --fd-danger-color-dark: #912919;
  --fd-danger-color-light: #ebb6ae;
  --fd-danger-color-hover: #eb361a;
  --fd-danger-color-focus: #eb361a;
  --fd-danger-box-shadow: 0;
  --fd-danger-box-shadow-hover: 0;
  --fd-danger-box-shadow-focus: 0 0 0 5px #eb361a79;
  --fd-danger-card-box-shadow: 0 4px 8px 0 #eb361a23, 0 6px 20px 0 #eb361a3d;
  --fd-danger-text-field-box-shadow-focus: 0 0 10px var(--fd-danger-color);

  --fd-info-color: #7aa9f0;
  --fd-info-color-dark: #10366f;
  --fd-info-color-light: #b5cef3;
  --fd-info-color-hover: #d5eb43;
  --fd-info-color-focus: #d5eb43;
  --fd-info-box-shadow: 0;
  --fd-info-box-shadow-hover: 0;
  --fd-info-box-shadow-focus: 0 0 0 5px #d5eb4379;
  --fd-info-card-box-shadow: 0 4px 8px 0 #d5eb4323, 0 6px 20px 0 #d5eb433d;
  --fd-info-text-field-box-shadow-focus: 0 0 10px var(--fd-info-color);

  --fd-dark-color: #000000;
  --fd-dark-color-hover: #000000;
  --fd-dark-color-focus: #000000;
  --fd-dark-box-shadow: 0;
  --fd-dark-box-shadow-hover: 0;
  --fd-dark-box-shadow-focus: 0 0 0 5px #00000079;
  --fd-dark-card-box-shadow: 0 4px 8px 0 #00000023, 0 6px 20px 0 #0000003d;
  --fd-dark-text-field-box-shadow-focus: 0 0 10px var(--fd-dark-color);

  --fd-light-color: #ffffff;
  --fd-light-color-hover: #ffffff;
  --fd-light-color-focus: #ffffff;
  --fd-light-box-shadow: 0;
  --fd-light-box-shadow-hover: 0;
  --fd-light-box-shadow-focus: 0 0 0 5px #ffffff79;
  --fd-light-card-box-shadow: 0 4px 8px 0 #ffffff23, 0 6px 20px 0 #ffffff3d;
  --fd-light-text-field-box-shadow-focus: 0 0 10px var(--fd-light-color);

  --fd-gray1-color: #efefef;
  --fd-gray1-color-hover: #efefef;
  --fd-gray1-color-focus: #efefef;
  --fd-gray1-box-shadow: 0;
  --fd-gray1-box-shadow-hover: 0;
  --fd-gray1-box-shadow-focus: 0 0 0 5px #efefef79;
  --fd-gray1-card-box-shadow: 0 4px 8px 0 #efefef23, 0 6px 20px 0 #efefef3d;
  --fd-gray1-text-field-box-shadow-focus: 0 0 10px var(--fd-gray1-color);

  --fd-gray2-color: #cfcfcf;
  --fd-gray2-color-hover: #f4f4f4;
  --fd-gray2-color-focus: #f4f4f4;
  --fd-gray2-box-shadow: 0;
  --fd-gray2-box-shadow-hover: 0;
  --fd-gray2-box-shadow-focus: 0 0 0 5px #cfcfcf79;
  --fd-gray2-card-box-shadow: 0 4px 8px 0 #cfcfcf23, 0 6px 20px 0 #cfcfcf3d;
  --fd-gray2-text-field-box-shadow-focus: 0 0 10px var(--fd-gray2-color);

  --fd-gray3-color: #a6a6a6;
  --fd-gray3-color-hover: #dedcdc;
  --fd-gray3-color-focus: #dedcdc;
  --fd-gray3-box-shadow: 0;
  --fd-gray3-box-shadow-hover: 0;
  --fd-gray3-box-shadow-focus: 0 0 0 5px #dedcdc79;
  --fd-gray3-card-box-shadow: 0 4px 8px 0 #dedcdc23, 0 6px 20px 0 #dedcdc3d;
  --fd-gray3-text-field-box-shadow-focus: 0 0 10px var(--fd-gray3-color);

  --fd-gray4-color: #373737;
  --fd-gray4-color-hover: #373737;
  --fd-gray4-color-focus: #373737;
  --fd-gray4-box-shadow: 0;
  --fd-gray4-box-shadow-hover: 0;
  --fd-gray4-box-shadow-focus: 0 0 0 5px #37373779;
  --fd-gray4-card-box-shadow: 0 4px 8px 0 #37373723, 0 6px 20px 0 #3737373d;
  --fd-gray4-text-field-box-shadow-focus: 0 0 10px var(--fd-gray4-color);

  --fd-gray5-color: #131313;
  --fd-gray5-color-hover: #131313;
  --fd-gray5-color-focus: #131313;
  --fd-gray5-box-shadow: 0;
  --fd-gray5-box-shadow-hover: 0;
  --fd-gray5-box-shadow-focus: 0 0 0 5px #13131379;
  --fd-gray5-card-box-shadow: 0 4px 8px 0 #13131323, 0 6px 20px 0 #1313133d;
  --fd-gray5-text-field-box-shadow-focus: 0 0 10px var(--fd-gray5-color);

  --fd-primary-background-color: #f8f7f7;
  --fd-secondary-background-color: #f8f7f7;
  --fd-tertiary-backgorund-color: #e4e4e4;

  --fd-primary-background: linear-gradient(314.27deg, rgba(213, 213, 213, 0.2) 5.41%, rgba(38, 248, 72, 0) 60.79%),
    radial-gradient(
        200.8% 42.83% at -43.47% -7.88%,
        rgba(247, 240, 229, 0.2) 2.33%,
        rgba(200, 168, 112, 0.2) 33.21%,
        rgba(249, 249, 252, 0.2) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        90.4% 89.29% at 4.4% 93.29%,
        rgba(255, 254, 253, 0.2) 11.06%,
        rgba(211, 172, 143, 0.2) 29.52%,
        rgba(133, 120, 215, 0.2) 42.19%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(193.27deg, #ddecf8 -2.71%, rgba(206, 204, 221, 0.14) 142.71%), #ffffff;
  --fd-secondary-background: var(--fd-secondary-background-color);
  --fd-tertiary-background: var(--fd-tertiary-backgorund-color);

  --fd-border-1: 1px solid;
  --fd-border-2: 2px solid;
  --fd-border-3: 3px solid;
  --fd-border-4: 4px solid;
  --fd-border-5: 5px solid;

  --fd-h1-font-size: 34px;
  --fd-h1-font-weight: 700;
  --fd-h1-margin: 16px 0;

  --fd-h2-font-size: 32px;
  --fd-h2-font-weight: 600;
  --fd-h2-margin: 10px 0;

  --fd-h3-font-size: 20px;
  --fd-h3-font-weight: 600;
  --fd-h3-margin: 6px 0;

  --fd-h4-font-size: 16px;
  --fd-h4-font-weight: 600;
  --fd-h4-margin: 4px 0;

  --fd-p1-font-size: 18px;
  --fd-p1-font-weight: 400;
  --fd-p1-margin: 0;

  --fd-p2-font-size: 16px;
  --fd-p2-font-weight: 400;
  --fd-p2-margin: 0;

  --fd-p3-font-size: 12px;
  --fd-p3-font-weight: 400;
  --fd-p3-margin: 0;

  --fd-label-font-size: 12px;
  --fd-label-font-weight: 600;
  --fd-label-margin: 0;

  --fd-border-radius-1: 10px;
  --fd-border-radius-2: 6px;
  --fd-border-radius-3: 8px;
  --fd-border-radius-4: 16px;
  --fd-border-radius-5: 2000px;

  --fd-padding-1: 10px;
  --fd-padding-2: 12px;
  --fd-padding-3: 16px;
  --fd-padding-4: 20px;
  --fd-padding-5: 30px;

  --fd-margin-1: 2px;
  --fd-margin-2: 12px;
  --fd-margin-3: 16px;
  --fd-margin-4: 20px;
  --fd-margin-5: 30px;

  --fd-ul-margin: 0;
  --fd-ul-padding: 0 0 0 30px;

  --fd-ol-margin: 0;
  --fd-ol-padding: 0 0 0 30px;

  --fd-z-index-1: 100;
  --fd-z-index-2: 200;
  --fd-z-index-3: 300;
  --fd-z-index-4: 400;
  --fd-z-index-5: 500;

  --fd-opacity-1: 0.2;
  --fd-opacity-2: 0.4;
  --fd-opacity-3: 0.6;
  --fd-opacity-4: 0.8;
  --fd-opacity-5: 1;
}

.home-page-tile {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
